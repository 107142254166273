import { useEffect } from 'react';
import env from '../../env';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { WorkflowChannelPayload } from '../types';

export default function useWorkflowChannel(
  backgroundJobName: string,
  onReceive: (workflow: WorkflowChannelPayload) => void,
) {
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?background_job_name=${backgroundJobName}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);

  useEffect(() => {
    subscribe(
      { channel: 'BackgroundJobChannel' },
      {
        received: onReceive,
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
